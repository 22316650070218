import { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { GA_PAGEVIEW, SET_APP_CONFIG } from '../redux/app';
import { SET_GUESTS, SET_EVENT_TYPE, SET_SELECTED_DATE, SET_URL_DATE } from '../redux/quote';

function App({ children, dispatch, config }) {
  const { slug, date } = useParams();

  if (date) {
      const urlDate = moment(date, 'DD-MM-YYYY')
      dispatch({
          type: SET_URL_DATE,
          payload: urlDate,
      });
      dispatch({
          type: SET_SELECTED_DATE,
          payload: { date: urlDate },
      });
  }

  useEffect(() => {
    const url = (!isUndefined(process.env.APP_URL)) ? process.env.APP_URL : 'https://app.cordero.cloud';
    const publicUrl = (!isUndefined(process.env.PUBLIC_URL)) ? process.env.PUBLIC_URL : 'https://portal.cordero.cloud';
    axios
      .get(`${url}/api/app/${slug}`)
      .then((response) => {
        const storeData = response.data;
        storeData.key = slug;
        storeData.appUrl = url;
        storeData.publicUrl = publicUrl;

        dispatch({
          type: SET_EVENT_TYPE,
          payload: first(storeData.bookingTypes),
        });

        dispatch({
          type: SET_GUESTS,
          payload: storeData.guestTypes,
        });

        dispatch({
          type: SET_APP_CONFIG,
          payload: storeData,
        });

        dispatch({
          type: GA_PAGEVIEW,
          payload: window.location.pathname,
        });

      })
      .catch((error) => error);
  }, [dispatch, slug]);

  if (isEmpty(config)) {
    return null;
  }

  return children;
}

App.propTypes = {
  children: PropTypes.element.isRequired,
  dispatch: PropTypes.func,
  config: PropTypes.shape({}),

};

const mapStateToProps = (state) => ({ config: state.config });

export default connect(mapStateToProps)(App);
