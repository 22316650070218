import ReactGA from "react-ga";

export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const GA_EVENT = 'GA_EVENT';
export const GA_PAGEVIEW = 'GA_PAGEVIEW';

export function appReducer(state = {}, action) {
  switch (action.type) {
    case SET_APP_CONFIG:

      return action.payload;
    case GA_PAGEVIEW:
      if (state.location.ga_tracking_code) {
        if (!ReactGA.ga()) {
          ReactGA.initialize(state.location.ga_tracking_code);
        }
        ReactGA.pageview(action.payload);
      }

      return state;
    case GA_EVENT:
      if (state.location.ga_tracking_code) {
        if (!ReactGA.ga()) {
          ReactGA.initialize(state.location.ga_tracking_code);
        }
        ReactGA.event(action.payload);
      }

      return state;
    default:
      return state;
  }
}
