import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ItemType from '../ItemType';
import { SUB_MENU_LIST } from '../../constants/MenuItemTypes';
import { moneyFormat, getAllChildItems } from '../../services/product';
import ListView from './ListView';
import {updateCateringMinimumSpend} from "../../redux/actions/rules";
import {OPEN_MENU} from "../../redux/quote";


function SubMenu({ item, dispatch, quote, config }) {
  const [modalVisible, showModal] = useState(false);
  let items = _.orderBy(
    _.filter(quote.menu, { parent_id: item.id }),
    '_lft',
  );

  const remaining = _.filter(getAllChildItems(item, quote.menu), (i) => {
    let children = _.filter(getAllChildItems(i, quote.menu), (child) => (child.selected === true && child.id !== i.id));
    return !_.isNull(i.minimum_choices) && children.length < i.minimum_choices;
  });


  const selectMenu = () => {
    dispatch({
      type: 'SELECT_MENU',
      payload: { item: item },
    });
    dispatch(updateCateringMinimumSpend());
    showModal(false);
  };

  const openMenu = () => {
    showModal(true);
    dispatch({
      type: OPEN_MENU,
      payload: { item: item },
    });
  };

  const removeMenu = () => {
    dispatch({
      type: 'REMOVE_MENU',
      payload: { item: item },
    });

    showModal(false);
  };
  const price = (config.showFullPrice) ? item.total : item.unit_price;
  const modal = () => (
    <Modal
    show={ modalVisible }
    onHide={() => showModal(false)}
    aria-labelledby="contained-modal-title-vcenter"
    size="lg"
    centered
    >
      <div className="close-modal" onClick={() => showModal(false)}>
        <i className={'far fa-times'}></i>
      </div>

        <Modal.Body className={'modal-text'}>
          <h1>{item.label } </h1>
          {
          items.map((i) => (
            (item.menu_item_type_id === SUB_MENU_LIST) ? <ListView item={i} key={i.id} /> : <ItemType item={i} key={i.id} />
          ))
          }

          <div
            className={`btn btn-secondary float-start `}
            onClick={() => removeMenu()}
          >
            Remove Menu
          </div>

            <div
              className={`btn btn-primary float-end ${remaining.length > 0 ? 'disabled' : ''}`}
              onClick={() => selectMenu()}
            >
              {!item.selected ? 'Select this menu' : 'Update Selection'}
            </div>

        </Modal.Body>
      </Modal>
      );


  return (
    <React.Fragment>
      {modal()}
      <div
        onClick={() => openMenu()}
        className={`blockCard ${(item.selected ? 'selected' : '')} ${(item.is_disabled ? ' disabled' : '')}`}
      >
        <div className="block-title row">
          <div className="col-6">{item.label}</div>
          <div className="col-6 text-end"> {(price > 0) ? `+ ${moneyFormat(price)}` :''}</div>
        </div>
        {item.description &&
          <div className="row">
            <div className="col-12">
              <div className="block-description">{item.description}</div>
            </div>
          </div>
        }
      </div>

    </React.Fragment>
  );
}
SubMenu.propTypes = {
  item: PropTypes.object,
  quote: PropTypes.object,
  config: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = function (state) {
  return {
    quote: state.quote,
    config: state.config,
  };
};

export default connect(mapStateToProps)(SubMenu);
