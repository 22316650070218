import React, { useState } from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getAllChildItems } from '../services/product';
import CustomTooltip from './CustomTooltip';
import {SUB_MENU} from "../constants/MenuItemTypes";
import {GA_EVENT} from "../redux/app";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-light-svg-icons";
import "../scss/components/submitQuote.scss";

function SubmitQuote({ config, quote, dispatch }) {
  const inputDefault = { first_name: '', last_name: '', email_address: '', contact_number: '' };
  const [modalVisible, setModalVisible] = useState(false);
  const [inputs, setInputs] = useState(inputDefault);
  const [saved, setSaved] = useState(false);
  const [savedQuote, setSavedQuote] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    event.persist();
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const itemsSelected = _.filter(quote.menu, (i) => { return i.minimum_choices !== null; });

  const disabled = [];
  _.each(itemsSelected, (item) => {
    if (_.filter(getAllChildItems(item, quote.menu), (f) => f.selected === true
            && f.menu_item_type_id === 2
            && f.id !== item.id).length !== item.minimum_choices
        && item.minimum_choices !== 0
    ) {
      if (quote.menu[item.parent_id] && quote.menu[item.parent_id].menu_item_type_id !== SUB_MENU) {
        disabled.push(item);
      } else {
        if (quote.menu[item.parent_id] && quote.menu[item.parent_id].selected) {
          disabled.push(item);
        }
      }
    }
  });

  const submitForm = (event) => {
    if (event) {
      event.preventDefault();
    }

    const selectedItems = _.orderBy(_.filter(quote.menu ,{selected: true}), '_lft');

    setErrors({});
    setLoading(true);
    axios.post(`${config.appUrl}/api/v2/lead`, {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email_address,
      contact_number: inputs.contact_number,
      guests: quote.guests,
      event_date: quote.selectedDate.date.format('DD-MM-YYYY'),
      key: config.app_id,
      app_id: 2,
      selected_items: selectedItems,
      event_type: quote.eventType,

    }, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        accept: 'application/json',
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setSaved(true);
        setInputs(inputDefault);
        setSavedQuote(data.quote);

        dispatch({
          type: GA_EVENT,
          payload: {
            category: 'Quote',
            action: 'Saved',
            value: data.quote.id || null,
          },
        });

      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        } else {
          setErrors({unknown: "Sorry, an unknown error has occurred. Please try again."});
        }
      });
  };

  return (
    <div>
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        aria-labelledby="save-quote-modal"
        size="sm"
        centered
      >
        <div className="close-modal" onClick={() => setModalVisible(false)} aria-hidden="true">
          <i className="far fa-times" />
        </div>
        <Modal.Body>
          {loading && (
              <div className="loading-container">
                <div>
                  <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
                </div>
                <h4 className="mt-3">Saving quote...</h4>
              </div>
          )}
          <form onSubmit={(e) => submitForm(e)} className={loading ? 'd-none' : ''}>
            <h3>Save your quote</h3>
            <p>We need a little information about you to save your quote, this means our team can retrieve it later should you wish to continue with your booking at {config.location.name}. You will also receive a copy by email.</p>
            {saved && savedQuote && (
              <Alert variant="success">
                Your quote has been successfully saved, your quote number is <strong>{ savedQuote.id }</strong> for your records.
              </Alert>
            )}
            {!_.isEmpty(errors) && (
                <Alert variant="danger">
                  <ul>
                  {Object.keys(errors).map((val, k) => {
                    return (<li key={k}>{errors[val]}</li>)
                  })}
                  </ul>
                </Alert>
            )}
            <div className="row mb-2">
              <div className="col">
                <label htmlFor="first_name">
                  First Name
                  </label>
                  <input
                    type="text"
                    className={"form-control "+(errors['first_name'] ? 'is-invalid' : '')}
                    id="first_name"
                    name="first_name"
                    value={inputs.first_name}
                    onChange={(e) => handleInputChange(e)}
                    required
                  />

              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="form-group">
                    <label htmlFor="last_name">
                    Last Name
                    </label>
                    <input
                      type="text"
                      className={"form-control "+(errors['last_name'] ? 'is-invalid' : '')}
                      id="last_name"
                      name="last_name"
                      value={inputs.last_name}
                      onChange={(e) => handleInputChange(e)}
                      required
                    />

                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="email_address">
                    Email Address
                  </label>
                    <input
                      type="text"
                      className={"form-control "+(errors['email_address'] ? 'is-invalid' : '')}
                      id="email_address"
                      name="email_address"
                      value={inputs.email_address}
                      onChange={(e) => handleInputChange(e)}
                      required
                    />

                </div>
              </div>
            </div>

            {config.showContactNumberField && (
            <div className="row mb-2">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="contact_number">
                    Contact Number
                  </label>
                  <input
                      type="text"
                      className={"form-control "+(errors['contact_number'] ? 'is-invalid' : '')}
                      id="contact_number"
                      name="contact_number"
                      value={inputs.contact_number}
                      onChange={(e) => handleInputChange(e)}
                  />

                </div>
              </div>
            </div>
            )}

            <div className="row">
              <div className="col text-end">
                <Button variant="secondary" onClick={() => setModalVisible(false)} className="mr-3 clear">
                  Close
                </Button>
                {!saved && <button type="submit" className="btn btn-primary ml-3">Save</button>}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="d-grid gap-2">
        {(_.filter(quote.menu, { selected: true }).length === 0 || disabled.length !== 0)
          ? (
            <CustomTooltip placement="top" text={`${disabled.length} products are required to continue`}>
              <button type="submit" id="final-summary-btn" className="btn btn-primary final-summary-btn btn-grey">Save your quote</button>
            </CustomTooltip>
          )
          : <button type="submit" id="final-summary-btn" className="btn btn-primary final-summary-btn" onClick={() => setModalVisible(true)} aria-hidden="true">Save your quote</button>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ quote: state.quote, config: state.config });

SubmitQuote.propTypes = {
  config: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
};
export default connect(mapStateToProps)(SubmitQuote);
