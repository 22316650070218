import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import {LOAD_QUOTE, STASH_MENU, UPDATE_QUANTITIES} from '../redux/quote';
import Sidebar from '../components/Sidebar';
import Main from '../components/Main';
import QuoteIntro from '../components/QuoteIntro';
import moment from "moment";
import {getMenuId} from "../redux/actions/rules";

function Quote({ config, dispatch, selectedDate, menu, stashedMenus, currentMenu }) {

  useEffect(() => {
    const date = selectedDate.date || moment();
    const menuId = getMenuId(currentMenu);

    if (menuId) {
      dispatch({ type: STASH_MENU, payload: { menuId, currentMenu } });
    }

    axios
      .get(`${config.appUrl}/api/v2/menu/${config.key}/${date.format('YYYY-MM-DD')}`)
      .then(function (response) {
        let storeData = response.data;
        let payload = storeData.menu;
        const receivedMenuId = String(getMenuId(payload));

        if (receivedMenuId && Object.keys(stashedMenus).includes(receivedMenuId)) {
          payload = stashedMenus[receivedMenuId];
        }

        dispatch({ type: LOAD_QUOTE, payload });
        dispatch({ type: UPDATE_QUANTITIES });
      })
      .catch((error) => {

      });

  }, [menu, selectedDate]);

  let logo = '/images/cordero.png';
  if (!_.isNull(config.location.logo_id)) {
      logo = `/quote/logo/location/${config.location.id}`;
  } else if (!_.isNull(config.company.logo_id)) {
      logo = `/quote/logo/${config.company.id}`
  }

  return (
    <div className="Quote" id={'quoteContainer'}>
      <div className="container-fluid g-0">
        <header className="App-header">
          <img src={config.appUrl + logo} />
        </header>
      </div>
      <div className={'container'}>
        <div className={'row no-gutters'}>
          <div className={'col-12 my-auto'}>

          </div>
        </div>
        <QuoteIntro />
        <div className="row">
          <div className={'col-md-8'}>
            <Main />
          </div>
          <div className={'col-md-4'}>
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  );
}
Quote.propTypes = {
  config: PropTypes.object,
  dispatch: PropTypes.func,
  selectedDate: PropTypes.object,
  stashedMenus: PropTypes.object,
  currentMenu: PropTypes.object,
}

const mapStateToProps = function (state) {
  return {
    config: state.config,
    selectedDate: state.quote.selectedDate,
    menu: state.menu,
    stashedMenus: state.quote.stashedMenus,
    currentMenu: state.quote.menu,
  };
};

export default connect(mapStateToProps)(Quote);
