import _ from 'lodash';
import moment from 'moment';
import React from 'react';

export default function Day({day, selectedDate, changeDate, blockedDates, month}) {
  const classes = ['day'];

  if (day.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
    classes.push('today');
  }

  // if (day.isBefore(moment())) {
  //   classes.push('past');
  // }

  if (!_.isUndefined(blockedDates) && !_.isUndefined(blockedDates[day.format('DD-MM-YYYY')])) {

    switch (blockedDates[day.format('DD-MM-YYYY')].status) {
      case 'Complete':
      case 'Live':
        classes.push('booked');
        break;
      case 'Provisional':
        classes.push('provisional');
        break;
      case 'closed':
        classes.push('closed');
        break;
      default:
        classes.push('available');
        break;
    }
    if (blockedDates[day.format('DD-MM-YYYY')].corona) {
      classes.push('corona');
    }
  } else {
    classes.push('available');
  }
  if (day.format('M') !== month.format('M')) {
    classes.push('previous-month');
  }
  if (day.format('DD-MM-YYYY') === selectedDate.format('DD-MM-YYYY')) {
    classes.push('current');
  }

  return (
    <span className={classes.join(' ')}
      onClick={() => changeDate(day) }
    >
      {(!day.format('M') !== month.format('M')) ? day.format('D'): ''}
    </span>
  );
}