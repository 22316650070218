import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { NOTE, PRODUCT_GROUP} from '../constants/MenuItemTypes';
import { moneyFormat } from '../services/product';

export default function MenuSummary({ menu, item, guests }) {
  const children = _.orderBy(
    _.filter(menu, (i) => (
      i.submenu === item.id
      && i.selected === true
      && i.menu_item_type_id !== NOTE
    )),
    '_lft',
  );

  return (
    <div className="mb-2 sidebar-menu-item">
      <div className="sidebar-item">
        {item.label}
        {(item.total  > 0) && <span className="float-end">
          {moneyFormat(item.total)}
        </span>}
      </div>
      <div className="children">
        {_.map(children, (i) => {
          if (i.menu_item_type_id === PRODUCT_GROUP) {
            return (
              <div key={i.id} className="sidebar-group">
                {i.label}
              </div>
            );
          }
          return (
            <div key={i.id}>
              {`${i.quantity} x ${i.label}`}
              <span className="float-end">{(i.total > 0) && `+ ${moneyFormat(i.total)}`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

MenuSummary.propTypes = {
  menu: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};
